/* eslint-disable */

import React, { useRef, memo } from 'react'

import styles from './style.module.scss'

import cn from 'classnames'

import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'

import { useStaticQuery, graphql } from 'gatsby'

function isDown(percentage) {
    return Number.parseFloat(percentage) < 0
}

export default memo(() => {
    const marqueeRef = useRef(null)
    const { allWordpressWpMarketStrip } = useStaticQuery(graphql`
        {
            allWordpressWpMarketStrip {
                nodes {
                    wordpress_id
                    title
                    acf {
                        subtitle
                        monthly_change
                        annual_change
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.marketTicker}>
            <marquee
                behavior="scroll"
                direction="left"
                scrollamount="9"
                ref={marqueeRef}
                onMouseOver={() => marqueeRef.current.stop()}
                onMouseLeave={() => marqueeRef.current.start()}
            >
                <div className={styles.scrollItems}>
                    {allWordpressWpMarketStrip.nodes.map(item => (
                        <div className={styles.item}>
                            <ul>
                                <li>
                                    <div className={styles.itemTitle}>
                                        {item.title}
                                    </div>
                                    <div className={styles.itemPrice}>
                                        {item.acf.subtitle}
                                    </div>
                                </li>
                                {item.acf.monthly_change && (
                                    <li>
                                        <div
                                            className={cn(styles.percent, {
                                                [styles.up]: !isDown(
                                                    item.acf.monthly_change
                                                ),
                                                [styles.down]: isDown(
                                                    item.acf.monthly_change
                                                )
                                            })}
                                        >
                                            <TiArrowSortedDown />
                                            <span>&nbsp;</span>{' '}
                                            {item.acf.monthly_change}
                                        </div>
                                        <div className={styles.period}>
                                            Monthly Change
                                        </div>
                                    </li>
                                )}
                                {item.acf.annual_change && (
                                    <li>
                                        <div
                                            className={cn(styles.percent, {
                                                [styles.up]: !isDown(
                                                    item.acf.monthly_change
                                                ),
                                                [styles.down]: isDown(
                                                    item.acf.monthly_change
                                                )
                                            })}
                                        >
                                            <TiArrowSortedDown />
                                            <span>&nbsp;</span>{' '}
                                            {item.acf.annual_change}
                                        </div>
                                        <div className={styles.period}>
                                            Annual Change
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    ))}
                </div>
            </marquee>
        </div>
    )
})
