import React, { createContext, useContext, useState, useMemo } from 'react'

import styles from './style.module.scss'

import { FaRegBuilding, FaRegListAlt, FaUser } from 'react-icons/fa'

import { IoIosSpeedometer } from 'react-icons/io'

import cn from 'classnames'

import { Link } from 'gatsby'

import { useCurrentUser } from '../protected-page'

import { ACCOUNT_TYPE_OWNER } from '../../constants'

const initialState = {
    open: false
}

const SidebarContext = createContext([initialState])

export const useSidebarOpen = () => {
    const [state, set] = useContext(SidebarContext)
    return [state.open, open => set(state => ({ ...state, open }))]
}

const ownerSidebar = [
    {
        title: 'Dashboard',
        link: '/owner-dashboard',
        icon: <IoIosSpeedometer />
    },
    {
        title: 'Properties',
        icon: <FaRegBuilding />,
        link: '/owner-properties'
    },
    {
        title: 'Offers',
        icon: <FaRegListAlt />,
        link: '/owner-offers'
    },
    {
        title: 'Profile',
        icon: <FaUser />,
        link: '/user-profile'
    }
]

const customerSidebar = [
    {
        title: 'Dashboard',
        link: '/customer-dashboard',
        icon: <IoIosSpeedometer />
    },

    {
        title: 'Offers',
        icon: <FaRegListAlt />,
        link: '/customer-offers'
    }
]

export default () => {
    const [open, setOpen] = useSidebarOpen()

    const [user] = useCurrentUser()

    const sidebar = useMemo(() => {
        if (user.account_type === ACCOUNT_TYPE_OWNER) return ownerSidebar
        return customerSidebar
    }, [user])

    return (
        <>
            <div className={cn(styles.sidebar, open && styles.open)}>
                {sidebar.map(item => (
                    <Link
                        className={styles.item}
                        to={item.link}
                        key={item.link}
                    >
                        <div className={styles.icon}>{item.icon}</div>
                        <span>{item.title}</span>
                    </Link>
                ))}
            </div>
            <div
                className={cn(styles.sidebarOverlay, open && styles.open)}
                onClick={() => setOpen(false)}
            ></div>
        </>
    )
}

export const SidebarWrapper = ({ children }) => {
    return <div className={styles.sidebarWrapper}>{children}</div>
}

export const SidebarProvider = ({ children }) => {
    return (
        <SidebarContext.Provider value={useState(initialState)}>
            {children}
        </SidebarContext.Provider>
    )
}
