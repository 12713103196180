import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

export default ({ render }) => {
    return (
        <StaticQuery
            query={graphql`
                {
                    allWordpressWpProjectCategory(
                        filter: { slug: { eq: "hot-projects" } }
                    ) {
                        edges {
                            node {
                                name
                                slug
                                wordpress_id
                            }
                        }
                    }
                    allWordpressWpProject(
                        sort: { order: DESC, fields: [date] }
                    ) {
                        edges {
                            node {
                                title
                                slug
                                project_category
                            }
                        }
                    }
                }
            `}
            render={data => {
                let hotProjectCategory =
                    data.allWordpressWpProjectCategory.edges[0]

                let hotProjects = data.allWordpressWpProject.edges
                    .filter(edge => {
                        return (
                            edge.node.project_category &&
                            edge.node.project_category.indexOf(
                                hotProjectCategory.node.wordpress_id
                            ) > -1
                        )
                    })
                    .slice(0, 8)

                return hotProjects.map(edge => render(edge))
            }}
        />
    )
}
