import React, { useState, Fragment, useMemo } from 'react'

import { Link } from 'gatsby'

import styles from './styles.module.scss'

import Logo from '../logo/white'

import { TiThMenu } from 'react-icons/ti'

import cn from 'classnames'

import SecondaryHeader from './secondary-header'

import SubscribePopup from '../subscribe-popup'

import { getToken } from '../../helpers'

import {
    LOGIN_TOKEN_LOCAL_STORAGE_KEY,
    ACCOUNT_TYPE_OWNER,
} from '../../constants'

import { useMyself } from '../hooks'
import { get } from 'lodash-es'

const logout = () => {
    delete localStorage[LOGIN_TOKEN_LOCAL_STORAGE_KEY]
    window.location.reload()
}

const getMenu = (mySelf) => {
    const menu = [
        {
            title: 'Subscribe now',
            isSubscribe: true,
        },

        {
            title: getToken() ? 'Sign out' : 'Sign in',
            link: getToken() ? '' : '/sign-in',
            onClick: () => {
                return getToken() ? logout() : null
            },
        },
    ]

    if (mySelf.account_type === ACCOUNT_TYPE_OWNER) {
        menu.splice(1, -1, { title: 'Dashboard', link: '/owner-dashboard' })
    } else if (mySelf.account_type) {
        menu.splice(1, -1, { title: 'Profile', link: '/user-profile' })
    }

    return menu
}

const Header = ({ noSecondary = false }) => {
    const [open, setOpen] = useState(false)

    const { response } = useMyself()

    const mySelf = useMemo(() => get(response, 'data') || {}, [response])

    const menu = useMemo(() => getMenu(mySelf), [mySelf])

    return (
        <Fragment>
            <header className={styles.header}>
                <div className="wrapper">
                    <div className="row middle-xs between-xs">
                        <div className="col-xs-10 col-sm-4">
                            <Link
                                to="/"
                                className={styles.logoLink}
                                title="Home"
                            >
                                <Logo className={styles.logo} />
                            </Link>
                        </div>
                        <div
                            className={cn(
                                'col-xs-2',
                                styles.menuButtonContainer
                            )}
                        >
                            <button
                                className={styles.menuButton}
                                onClick={() => setOpen(!open)}
                            >
                                <TiThMenu />
                            </button>
                        </div>
                        <div
                            className={cn(
                                'col-xs-12 col-sm-8',
                                {
                                    [styles.open]: open,
                                },
                                styles.menuColumn
                            )}
                        >
                            <ul className={styles.menu}>
                                {menu.map((m) => (
                                    <li key={m.title}>
                                        {m.isSubscribe ? (
                                            <SubscribePopup modal>
                                                <div
                                                    className={styles.menuLink}
                                                >
                                                    {m.icon || ''} {m.title}
                                                </div>
                                            </SubscribePopup>
                                        ) : (
                                            <Link
                                                to={m.link}
                                                className={styles.menuLink}
                                                title={m.title}
                                                onClick={
                                                    m.onClick
                                                        ? m.onClick
                                                        : () => {}
                                                }
                                            >
                                                {m.icon || ''} {m.title}
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {!noSecondary && <SecondaryHeader />}
        </Fragment>
    )
}

export default Header
