import React, { useEffect, useState } from 'react'
import Portal from '../portal'
import styles from './style.module.scss'
import { TiDelete } from 'react-icons/ti'

export default ({
    onClose,
    header,
    children,
    footer,
    open,
    closeWhenClickOutside = true,
}) => {
    const [bodyScroll, setBodyScroll] = useState(0)

    useEffect(() => {
        if (open) {
            setBodyScroll(
                document.documentElement.scrollTop || document.body.scrollTop
            )
            document.body.classList.add('popup-open')
        } else {
            document.body.classList.remove('popup-open')
            document.documentElement.scrollTop = bodyScroll
            document.body.scrollTop = bodyScroll
        }
        return () => document.body.classList.remove('popup-open')
    }, [open])

    useEffect(() => {
        const handleKeyUp = (e) => {
            if (e.key === 'Escape') onClose()
        }

        document.addEventListener('keyup', handleKeyUp)

        return () => document.removeEventListener('keyup', handleKeyUp)
    }, [])

    return (
        open && (
            <Portal id="popup">
                <div className={styles.wrapper}>
                    <div
                        className={styles.overlay}
                        onClick={() => {
                            if (closeWhenClickOutside) onClose()
                        }}
                    ></div>
                    <div className={styles.content}>
                        <div className={styles.header}>
                            <h2>{header}</h2>
                            <TiDelete
                                className={styles.close}
                                onClick={onClose}
                            />
                        </div>
                        <div className={styles.body}>{children}</div>
                        <div className={styles.footer}>{footer}</div>
                    </div>
                </div>
            </Portal>
        )
    )
}
