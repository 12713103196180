import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { StaticQuery, graphql } from 'gatsby'

import Header from '../header'

import DashboardHeader from '../dashboard-header'

import Footer from '../footer'

import './styles.scss'

import SocialBar from '../social-bar'

import Breadcrumb from '../breadcrumb'

import MarketTicker from '../market-ticker'

import ScrollTop from '../scroll-top'

const Layout = ({
    children,
    breadcrumb,
    marketTicker,
    noSecondaryHeader,
    noHeader,
    noFooter,
    noScrollTop,
    noSocialBar,
    dashboardHeader
}) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={data => (
            <Fragment>
                {!noHeader ? <Header noSecondary={noSecondaryHeader} /> : null}
                {dashboardHeader && <DashboardHeader />}
                {breadcrumb && <Breadcrumb breadcrumb={breadcrumb} />}
                {marketTicker && <MarketTicker />}
                <main>{children}</main>
                {!noSocialBar ? <SocialBar /> : null}
                {!noScrollTop ? <ScrollTop /> : null}
                {!noFooter ? <Footer /> : null}
            </Fragment>
        )}
    />
)

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
