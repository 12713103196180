import React, { useEffect } from 'react'

import styles from './style.module.scss'

import Logo from '../logo/white'

import cn from 'classnames'

import { Link } from 'gatsby'

import { FiMenu } from 'react-icons/fi'

import { useSidebarOpen } from '../dashboard-sidebar'

import { LOGIN_TOKEN_LOCAL_STORAGE_KEY } from '../../constants'

export default () => {
    const [open, setOpen] = useSidebarOpen()

    useEffect(() => {
        if (open) document.body.classList.add('dashboard-header--open')
        else document.body.classList.remove('dashboard-header--open')
    }, [open])

    const logout = () => {
        delete localStorage[LOGIN_TOKEN_LOCAL_STORAGE_KEY]
        window.location.reload()
    }

    return (
        <header className={cn(styles.header)}>
            <div className={styles.logoAndMenuButton}>
                <button
                    className={styles.menuButton}
                    onClick={() => setOpen(!open)}
                >
                    <FiMenu />
                </button>
                <Link to="/" className={styles.logoLink} title="Home">
                    <Logo className={styles.logo} />
                </Link>
            </div>

            <div
                className={styles.logout}
                title={'Logout'}
                onClick={() => logout()}
            >
                Logout
            </div>
        </header>
    )
}
