import React, { useEffect } from 'react'
import styles from './style.module.scss'

export default ({
    client,
    className,
    slot,
    layout,
    layoutKey,
    format,
    responsive
}) => {
    useEffect(() => {
        if (typeof window !== 'undefined')
            (window.adsbygoogle = window.adsbygoogle || []).push({})
    }, [])

    return (
        <ins
            className={`${className} ${styles.ad} adsbygoogle`}
            data-ad-client={client}
            data-ad-slot={slot}
            data-ad-layout={layout}
            data-ad-layout-key={layoutKey}
            data-ad-format={format}
            data-full-width-responsive={responsive}
        ></ins>
    )
}
