import React from 'react'

import { Link } from 'gatsby'

import styles from './style.module.scss'

import ProjectsQuery from './ProjectsQuery'

import DevelopersQuery from './DevelopersQuery'

import cn from 'classnames'

import GoogleAd from '../google-ad'

const QUICK_LINKS = [
    {
        title: 'Areas',
        link: '/areas',
    },
    {
        title: 'Developers',
        link: '/developers',
    },
    {
        title: 'Advertise with us',
        link: '/advertise-with-us',
    },
    {
        title: 'Mortgage Calculator',
        link: '/mortgage-calculator',
    },
    {
        title: 'Blog',
        link: '/blog',
    },
    {
        title: 'Market reports',
        link: '/market-research',
    },
    {
        title: 'Upcoming launches',
        link: '/upcoming-launches',
    },
    {
        title: 'Property services',
        link: '/property-services',
    },
]
const Footer = () => (
    <>
        <GoogleAd
            key={Math.random()}
            client="ca-pub-5451792925218475"
            slot="9260468618"
            format="auto"
            responsive="true"
        />
        <footer className={styles.footer}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-6 col-sm-4">
                        <h3 className={styles.footerHeader}>Hot projects</h3>
                        <ul>
                            <ProjectsQuery
                                render={(edge) => (
                                    <li key={edge.node.slug}>
                                        <Link
                                            to={'/project/' + edge.node.slug}
                                            title={edge.node.title}
                                        >
                                            {edge.node.title}
                                        </Link>
                                    </li>
                                )}
                            />
                            <li>
                                <Link to={'/hot-projects/'} title={'View all'}>
                                    View all
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-6 col-sm-4">
                        <h3 className={styles.footerHeader}>Developers</h3>
                        <ul>
                            <DevelopersQuery
                                render={(edge) => (
                                    <li key={edge.node.slug}>
                                        <Link
                                            to={'/developer/' + edge.node.slug}
                                            title={edge.node.name}
                                        >
                                            {edge.node.name}
                                        </Link>
                                    </li>
                                )}
                            />
                            <li>
                                <Link to={'/developers/'} title={'View all'}>
                                    View all
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-6 col-sm-4">
                        <h3 className={styles.footerHeader}>Quick Links</h3>
                        <ul>
                            {QUICK_LINKS.map((q) => (
                                <li key={q.link}>
                                    <Link to={q.link} title={q.title}>
                                        {q.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={styles.copy}>
                    &copy; 2019 - 2020 all rights reserved. Built with ♥ in
                    Dubai.
                </div>
                <div className={styles.legal}>
                    الخدمات العقارية مزودة من قبل شركة:
                    <a
                        className={styles.link}
                        href="https://www.armsmcgregor.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ارمز اند مكغريجر الدولية للعقارات.
                    </a>
                    <span> رقم الرخصة: 690774 </span>
                    <span> رخصة دائرة الأراضي والأملاك: 12283 </span>
                </div>
                <div className={cn(styles.legal, styles.last)}>
                    الخدمات الالكترونية مقدمة من قبل شركة :
                    <a
                        className={styles.link}
                        href="https://www.productionbb.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        بيلو اند بيوند برودكشن م م ح
                    </a>
                    <span> رقم الرخصة: 5857/2014</span>
                </div>
            </div>
        </footer>
    </>
)

export default Footer
