import React, { useState } from 'react'

import styles from './style.module.scss'

import cn from 'classnames'

import { Link, graphql, StaticQuery, navigate } from 'gatsby'

import { FiChevronRight } from 'react-icons/fi'

import {
    TiGlobeOutline,
    TiGroupOutline,
    TiTags,
    TiInfoLargeOutline,
    TiSpannerOutline,
} from 'react-icons/ti'

import ProtectedSignedInContent from '../protected-signed-in-content'

import { ACCOUNT_TYPE_OWNER } from '../../constants'

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max - 1))
}

let _randomProject = null

function getRandomProject(edges) {
    if (_randomProject) return _randomProject

    return edges[getRandomInt(edges.length)].node
}

function getName(data, ids) {
    let filtered = data.edges.filter(
        (edge) => ids.indexOf(edge.node.wordpress_id) > -1
    )
    if (filtered.length) {
        return ' | ' + filtered[0].node.name
    }

    return ''
}

const filterProjectsByTerm = (data, term) => {
    return data.allWordpressWpProject.edges
        .filter((edge) => {
            if (!term || !term.length) return false
            try {
                let match = edge.node.title
                    .toLowerCase()
                    .match(term.toLowerCase())
                match =
                    match ||
                    getName(data.allWordpressWpAreas, edge.node.areas)
                        .toLowerCase()
                        .match(term.toLowerCase())
                match =
                    match ||
                    getName(data.allWordpressWpDevelopers, edge.node.developers)
                        .toLowerCase()
                        .match(term.toLowerCase())
                return match
            } catch (e) {
                return false
            }
        })
        .slice(0, 5)
}

const SearchBox = ({
    innerPage,
    onTermChange = () => {},
    noNegativeMargin,
    hideLinks = false,
}) => {
    const [searchTerm, setSearchterm] = useState(null)
    const [currentActive, setCurrentActive] = useState(0)

    return (
        <StaticQuery
            query={graphql`
                {
                    allWordpressWpProject {
                        edges {
                            node {
                                title
                                slug
                                areas
                                developers
                            }
                        }
                    }
                    allWordpressWpAreas {
                        edges {
                            node {
                                name
                                wordpress_id
                            }
                        }
                    }
                    allWordpressWpDevelopers {
                        edges {
                            node {
                                name
                                wordpress_id
                            }
                        }
                    }
                }
            `}
            render={(data) => {
                const suggestions = filterProjectsByTerm(data, searchTerm)
                return (
                    <div
                        className={cn(
                            styles.searchBox,
                            innerPage && styles.innerPage,
                            noNegativeMargin && styles.noNegativeMargin
                        )}
                    >
                        <div className={cn('wrapper', styles.wrapper)}>
                            {!hideLinks && (
                                <ul className={styles.tabs}>
                                    <li>
                                        <Link to="/areas" title="Areas">
                                            <TiGroupOutline />
                                            <span>areas</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/developers"
                                            title="Developers"
                                        >
                                            <TiGlobeOutline />
                                            <span>developers</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/post-enquiry"
                                            title="Post enquiry"
                                        >
                                            <TiInfoLargeOutline />
                                            <span>post enquiry</span>
                                            <div className={styles.freeBadge}>
                                                Free
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <ProtectedSignedInContent
                                            whenNotLoggedIn={
                                                <span
                                                    className={styles.fakeLink}
                                                >
                                                    <TiTags />
                                                    <span>list property</span>
                                                    <div
                                                        className={
                                                            styles.freeBadge
                                                        }
                                                    >
                                                        Free
                                                    </div>
                                                </span>
                                            }
                                            accountType={ACCOUNT_TYPE_OWNER}
                                            whenLoggedIn={
                                                <Link
                                                    to="/list-property"
                                                    title="List property"
                                                >
                                                    <TiTags />
                                                    <span>list property</span>
                                                    <div
                                                        className={
                                                            styles.freeBadge
                                                        }
                                                    >
                                                        Free
                                                    </div>
                                                </Link>
                                            }
                                        />
                                    </li>
                                    <li>
                                        <Link
                                            to="/property-services"
                                            title="Property services"
                                        >
                                            <TiSpannerOutline />
                                            property services
                                        </Link>
                                    </li>
                                </ul>
                            )}
                            <div className={styles.content}>
                                {!hideLinks && (
                                    <div className={styles.links}>
                                        <Link
                                            to="/high-yield-properties"
                                            title="Check out high yield properties"
                                        >
                                            Check out high yield properties
                                        </Link>
                                        <span>
                                            <Link
                                                to="/upcoming-launches"
                                                title="Upcoming launches"
                                            >
                                                Check out new launches
                                            </Link>
                                            <FiChevronRight
                                                className={styles.chevronRight}
                                            />
                                        </span>
                                    </div>
                                )}
                                <input
                                    type="search"
                                    className={styles.searchInput}
                                    placeholder={
                                        'Try ' +
                                        getRandomProject(
                                            data.allWordpressWpProject.edges
                                        ).title
                                    }
                                    onChange={(e) => {
                                        setSearchterm(e.target.value)
                                        onTermChange(e.target.value)
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            if (suggestions.length) {
                                                navigate(
                                                    '/project/' +
                                                        suggestions[
                                                            currentActive
                                                        ].node.slug
                                                )
                                            }
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key === 'ArrowDown') {
                                            setCurrentActive((oldActive) =>
                                                oldActive + 1 <
                                                suggestions.length
                                                    ? oldActive + 1
                                                    : 0
                                            )
                                        }
                                        if (e.key === 'ArrowUp') {
                                            setCurrentActive((oldActive) =>
                                                oldActive - 1 >= 0
                                                    ? oldActive - 1
                                                    : suggestions.length - 1
                                            )
                                        }
                                    }}
                                />
                                <ul className={styles.suggestions}>
                                    {suggestions.map((edge, i) => (
                                        <li
                                            className={
                                                i === currentActive &&
                                                styles.active
                                            }
                                        >
                                            <Link
                                                title={edge.node.title}
                                                to={'project/' + edge.node.slug}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        edge.node.title +
                                                        getName(
                                                            data.allWordpressWpDevelopers,
                                                            edge.node.developers
                                                        ) +
                                                        getName(
                                                            data.allWordpressWpAreas,
                                                            edge.node.areas
                                                        ),
                                                }}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }}
        />
    )
}

export default SearchBox
