import React from 'react'

import { Link } from 'gatsby'

import { TiChevronRightOutline } from 'react-icons/ti'

import styles from './style.module.scss'

const Breadcrumb = ({ breadcrumb }) => {
    return (
        <ul className={styles.breadcrumb}>
            {breadcrumb.map((item, i) => (
                <li key={item.title}>
                    {item.link ? (
                        <Link to={item.link}>{item.title}</Link>
                    ) : (
                        item.title
                    )}
                    {i < breadcrumb.length - 1 && (
                        <TiChevronRightOutline
                            className={styles.chevronRight}
                        />
                    )}
                </li>
            ))}
        </ul>
    )
}

export const DashboardBreadcrumb = ({ breadcrumb }) => {
    return (
        <div className={styles.dashboardBreadcrumbContainer}>
            <Breadcrumb breadcrumb={breadcrumb} />
        </div>
    )
}

export default ({ breadcrumb }) => {
    return (
        <div className={styles.breadcrumbContainer}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12">
                        <Breadcrumb breadcrumb={breadcrumb} />
                    </div>
                </div>
            </div>
        </div>
    )
}
