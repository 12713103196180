import React, { useState, useEffect, createContext, useContext } from 'react'

import {
    LOGIN_TOKEN_LOCAL_STORAGE_KEY,
    USER_LOCAL_STORAGE_KEY,
    ACCOUNT_TYPE_OWNER,
} from '../../constants'

import { isEmpty } from 'lodash-es'

import { navigate } from 'gatsby'

import { useMyself } from '../hooks'

import { getToken } from '../../helpers'

import { get } from 'lodash-es'

const DEFAULT_STATE = {
    user: {
        ID: null,
        display_name: '',
        user_email: '',
        user_registered: null,
    },
}

export const ProtectedPageContext = createContext([DEFAULT_STATE])

export const useProtectedPageContext = () => {
    return useContext(ProtectedPageContext)
}

export const useCurrentUser = () => {
    const [state] = useProtectedPageContext()
    return [state.user]
}

export default ({ children, requiredAccountType = [ACCOUNT_TYPE_OWNER] }) => {
    const [state, set] = useState(DEFAULT_STATE)

    const [allowed, setAllowed] = useState(false)

    const { statusCode, response, loading } = useMyself({ token: getToken() })

    useEffect(() => {
        if (isEmpty(getToken())) {
            navigate('/sign-in')
        }
    }, [])

    useEffect(() => {
        switch (statusCode) {
            case 401:
                setAllowed(false)
                delete localStorage[LOGIN_TOKEN_LOCAL_STORAGE_KEY]
                delete localStorage[USER_LOCAL_STORAGE_KEY]
                navigate('/sign-in')
                break
            case 200: {
                if (isEmpty(response)) break

                const accountType = get(response, 'data.account_type')

                if (requiredAccountType.indexOf(accountType) > -1) {
                    setAllowed(true)
                } else {
                    setAllowed(false)
                }

                break
            }
        }
    }, [statusCode, response])

    useEffect(() => {
        if (statusCode === 200 && !isEmpty(response)) {
            set({
                user: response.data,
            })
        }
    }, [response, statusCode])

    useEffect(() => {
        if (!allowed && !loading && statusCode) {
            navigate('/sign-in')
        }
    }, [allowed, loading, statusCode])

    return (
        <ProtectedPageContext.Provider value={[state, set]}>
            {allowed ? children : ''}
        </ProtectedPageContext.Provider>
    )
}
