import React, { useEffect, useState } from 'react'

import styles from './style.module.scss'

import { TiArrowUpOutline } from 'react-icons/ti'

import cn from 'classnames'

function goToTop() {
    window.scrollTo(0, 0)
}

function scrollTop() {
    var doc = document.documentElement
    // var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    return top
}

export default () => {
    const [scrolled, setScrolled] = useState(false)

    useEffect(() => {
        if (typeof window === 'undefined') return

        window.addEventListener('scroll', () => {
            setScrolled(scrollTop() > 300)
        })
    }, [])
    return (
        <div
            className={cn(styles.scrollTop, scrolled && styles.scrolled)}
            onClick={() => goToTop()}
        >
            <TiArrowUpOutline />
        </div>
    )
}
