import { useState, useEffect } from 'react'
import { useDebounceCallback } from '@react-hook/debounce'
import { isString, isEmpty } from 'lodash-es'
import { getToken } from '../../helpers'
import { LOGIN_TOKEN_LOCAL_STORAGE_KEY } from '../../constants'
import { get } from 'lodash-es'
import queryString from 'query-string'

function prepareFormFields(formRef) {
    let data = new FormData(formRef.current)

    var dataObject = {}

    data.forEach(function (value, key) {
        if (isString(value)) return (dataObject[key] = value.trim())

        dataObject[key] = value
    })

    return dataObject
}

export const useApiCall = ({
    formRef = null,
    url,
    method,
    headers: defaultHeaders = {},
    additionalData = null,
    fileUpload = false,
}) => {
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({})
    const [statusCode, setStatusCode] = useState(null)

    let headers = { ...defaultHeaders }

    const submit = useDebounceCallback(
        async (submitData) => {
            setLoading(true)
            setResponse({})

            let params = {},
                body = null

            if (formRef) {
                params = Object.assign({}, params, prepareFormFields(formRef))
            }

            if (additionalData) {
                params = Object.assign({}, params, additionalData)
            }

            if (submitData) {
                params = Object.assign({}, params, submitData)
            }

            if (!isEmpty(params)) {
                if (!fileUpload) {
                    body = JSON.stringify(params)
                } else {
                    body = new FormData(formRef.current)
                }
            }

            if (!isEmpty(getToken())) {
                headers.Authorization = 'Bearer ' + getToken()
            }

            headers = {
                'Content-Type': 'application/json',
                ...headers,
            }

            if (fileUpload) delete headers['Content-Type']

            try {
                const response = await fetch(
                    process.env.GATSBY_PEP_API_URL + url,
                    {
                        method,
                        mode: 'cors',
                        headers,
                        body,
                    }
                )

                setStatusCode(response.status)

                setResponse(await response.json())
            } catch (ex) {
                console.error(ex)
            } finally {
                setLoading(false)
            }
        },
        0,
        true
    )

    useEffect(() => {
        // auto call on get requests
        if (method === 'GET') submit()
    }, [])

    return { statusCode, loading, submit, response }
}

export const useRegisterAccount = ({ formRef }) => {
    return useApiCall({
        formRef,
        url: '/register-account',
        method: 'POST',
    })
}

export const useSignIn = ({ formRef }) => {
    const { statusCode, response, ...rest } = useApiCall({
        formRef,
        url: '/sign-in',
        method: 'POST',
    })

    useEffect(() => {
        const token = get(response, 'data.token')
        if (statusCode === 200 && token) {
            localStorage[LOGIN_TOKEN_LOCAL_STORAGE_KEY] = token
        }
    }, [response, statusCode])

    return { statusCode, response, ...rest }
}

export const useSendResetInstructions = ({ formRef }) => {
    return useApiCall({
        formRef,
        url: '/send-reset-instructions',
        method: 'POST',
    })
}

export const useChangePassword = ({ formRef, code }) => {
    return useApiCall({
        formRef,
        url: '/change-password',
        method: 'POST',
        additionalData: { code },
    })
}

export const useMyself = () => {
    return useApiCall({
        url: `/myself`,
        method: 'GET',
    })
}

export const useSaveMyProfile = ({ formRef }) => {
    return useApiCall({
        url: `/save-my-profile`,
        method: 'POST',
        fileUpload: true,
        formRef,
    })
}

export const usePropertyLocations = () => {
    return useApiCall({
        url: '/property-locations',
        method: 'GET',
    })
}

export const usePropertyTypes = () => {
    return useApiCall({
        url: '/property-types',
        method: 'GET',
    })
}

export const usePropertyStatus = () => {
    return useApiCall({
        url: '/property-status',
        method: 'GET',
    })
}

export const useSaveProperty = ({ formRef }) => {
    return useApiCall({
        url: '/property',
        method: 'POST',
        formRef,
        fileUpload: true,
    })
}

function _getNumberOfChecks() {
    return [1, 2, 4, 6, 12].map((i) => ({
        label: i + ' check' + (i > 1 ? 's' : ''),
        value: i,
    }))
}

export const useNumberOfChecks = () => {
    const [numberOfChecks] = useState(_getNumberOfChecks())

    return numberOfChecks
}

export const useOwnerProperties = () => {
    return useApiCall({
        url: '/property',
        method: 'GET',
    })
}

export const useDeleteProperty = () => {
    return useApiCall({
        url: '/property',
        method: 'DELETE',
    })
}

export const useSaveOffer = ({ formRef, additionalData }) => {
    return useApiCall({
        url: '/offer',
        method: 'POST',
        formRef,
        additionalData,
    })
}

function _parseQueryString() {
    if (typeof window !== 'undefined') {
        return queryString.parse(window.location.search)
    }

    return null
}

export const useQueryParams = () => {
    const [params] = useState(_parseQueryString())

    return params
}

function _getCurrentPath() {
    if (typeof window !== 'undefined') {
        return window.location.pathname
    }
    return null
}

export const useCurrentPath = () => {
    const [path] = useState(_getCurrentPath())

    return path
}

export const useOwnerOffers = () => {
    return useApiCall({
        url: '/offer',
        method: 'GET',
    })
}

export const useChangeOfferStatus = () => {
    return useApiCall({
        url: '/change-offer-status',
        method: 'POST',
    })
}

export const useOwnerStats = () => {
    return useApiCall({
        url: '/owner-stats',
        method: 'GET',
    })
}

export const useSaveEnquiry = ({ formRef }) => {
    return useApiCall({
        url: '/enquiry',
        method: 'POST',
        formRef,
    })
}

export const useEnquiryPupose = () => {
    return useApiCall({
        url: '/enquiry-purpose',
        method: 'GET',
    })
}
