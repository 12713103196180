import React from 'react'

import Popup from 'reactjs-popup'

import Form from '../form'

import Input from '../input'

import Button from '../button'

import styles from './style.module.scss'

import Logo from '../logo'

import { TiDelete, TiLockClosed } from 'react-icons/ti'

export default ({ children }) => {
    return (
        <Popup
            trigger={children}
            modal
            closeOnDocumentClick
            contentStyle={{ border: 0, width: 'initial' }}
        >
            {close => (
                <Form
                    className={styles.modal}
                    onSuccess={close}
                    requiredFields={['your_name', 'your_email']}
                    errorMessageClass={styles.errorMessage}
                >
                    <TiDelete className={styles.close} onClick={close} />
                    <Logo className={styles.logo} />
                    <p>
                        Subscribe now & we'll email you a w​eekly update on the
                        property of your interest.
                    </p>
                    <Input label="Your name" required />
                    <Input label="Your email" required />
                    <Button className={styles.btn} secondary>
                        Subscribe now
                    </Button>
                    <p className={styles.statement}>
                        <TiLockClosed />
                        Your details will be treated with respect in compliance
                        with our privacy policy.
                    </p>
                </Form>
            )}
        </Popup>
    )
}
