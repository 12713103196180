import { LOGIN_TOKEN_LOCAL_STORAGE_KEY } from '../constants'
import { useState } from 'react'
import { get } from 'lodash-es'

export function getToken() {
    if (typeof window !== 'undefined') {
        const token = localStorage[LOGIN_TOKEN_LOCAL_STORAGE_KEY]
        return token
    }
    return null
}

export function transformList(response) {
    let list = get(response, 'data')

    if (!list) return []

    return list
        .filter((item) => !item.name.match(/not available/i))
        .map((item) => ({
            value: item.term_id,
            label: item.name,
        }))
}
