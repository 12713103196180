import React, { useContext } from 'react'

import { FormContext } from '../form'

import { Link } from 'gatsby'

import styles from './style.module.scss'

import cn from 'classnames'

const LinkButton = ({
    link,
    children,
    target,
    className,
    disabled,
    secondary,
    title
}) => {
    if (link.match('http') || link.match('mailto')) {
        // this is likely external link
        return (
            <a
                className={cn(styles.btn, className, {
                    [styles.secondary]: secondary
                })}
                href={link}
                target={target}
                title={title}
            >
                {children}
            </a>
        )
    }
    // use gatsby link by default
    return (
        <Link
            className={cn(styles.btn, className, {
                [styles.secondary]: secondary,
                [styles.disabled]: disabled
            })}
            to={link}
            title={title}
            disabled={disabled}
        >
            {children}
        </Link>
    )
}
function submitParentForm(context) {
    if (context) {
        context.submit()
    }
}

const NormalButton = ({
    children,
    className,
    onClick,
    secondary,
    disabled
}) => {
    const formContext = useContext(FormContext)

    return (
        <button
            className={cn(styles.btn, className, {
                [styles.secondary]: secondary,
                [styles.disabled]: disabled
            })}
            onClick={() => {
                if (disabled) return
                submitParentForm(formContext)
                onClick && onClick()
            }}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

export default ({ link, ...props }) => {
    if (link) return <LinkButton {...props} link={link} />
    else return <NormalButton {...props} />
}
