import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

export default ({ render }) => {
    return (
        <StaticQuery
            query={graphql`
                {
                    allWordpressWpDevelopers(
                        filter: { acf: { show_in_footer: { eq: true } } }
                        limit: 8
                    ) {
                        edges {
                            node {
                                name
                                slug
                            }
                        }
                    }
                }
            `}
            render={data =>
                data.allWordpressWpDevelopers.edges.map(edge => render(edge))
            }
        />
    )
}
