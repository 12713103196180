import React, { useState, Fragment, useEffect } from 'react'

import {
    FaCheckSquare,
    FaRegSquare,
    FaRegCircle,
    FaRegDotCircle,
    FaStarOfLife,
} from 'react-icons/fa'

import DatePicker from 'react-datepicker'

import styles from './style.module.scss'

import cn from 'classnames'
import { isFunction } from 'lodash-es'

export function words(s, count = 2) {
    return s.split(' ').slice(0, count).join('_')
}

export function encodeID(s = '') {
    if (s === '') return '_'
    return s.toLowerCase().replace(/[^a-zA-Z0-9.-]/g, function (match) {
        return '_'
    })
}

const TextInput = ({
    type,
    label,
    name: _name,
    placeholder,
    onChange = () => {},
    required = false,
    className,
    value: defaultValue,
    disabled,
}) => {
    let name = _name || encodeID(words(label, 2))
    const [value, setValue] = useState(defaultValue)

    return (
        <div
            className={cn(
                styles.inputContainer,
                className,
                disabled && styles.disabled
            )}
        >
            <label htmlFor={name}>
                {label}
                {required ? <FaStarOfLife className={styles.required} /> : null}
            </label>
            <input
                value={value}
                disabled={disabled}
                required={required}
                name={name}
                id={name}
                placeholder={placeholder || label}
                type={type}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
            />
        </div>
    )
}

const Checkbox = ({ label, name, children, required }) => {
    const [checked, setChecked] = useState(false)
    return (
        <div className={styles.checkboxContainer}>
            <label className={styles.checkbox}>
                {checked ? <FaCheckSquare /> : <FaRegSquare />}
                <input
                    type="checkbox"
                    required={required}
                    value={label}
                    name={name}
                    onChange={(e) => setChecked(e.target.checked)}
                />
                {children || <span>{label}</span>}
            </label>
        </div>
    )
}

const Radio = ({
    labels,
    values = [],
    name,
    children,
    containerClassName,
    required,
    onChange = () => {},
}) => {
    const [value, setValue] = useState(null)

    useEffect(() => {
        if (isFunction(onChange)) onChange(value)
    }, [value])

    return (
        <div className={containerClassName}>
            {labels.map((label, i) => (
                <div className={styles.radioContainer} key={label}>
                    <label className={styles.radio}>
                        {value === (values[i] || label) ? (
                            <FaRegDotCircle />
                        ) : (
                            <FaRegCircle />
                        )}
                        <input
                            type="radio"
                            value={values[i] || label}
                            name={name}
                            required={required}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        {children || <span>{label}</span>}
                    </label>
                </div>
            ))}
        </div>
    )
}

const TextArea = ({
    type,
    label,
    name: _name,
    placeholder,
    required,
    rows,
    onChange = () => {},
}) => {
    let name = _name || encodeID(words(label, 2))
    return (
        <div className={styles.textAreaContainer}>
            <label htmlFor={name}>
                {label}
                {required ? <FaStarOfLife className={styles.required} /> : null}
            </label>
            <textarea
                name={name}
                id={name}
                placeholder={placeholder || label}
                type={type}
                rows={rows}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )
}

const DateInput = ({ mode, name, label }) => {
    const [date, setDate] = useState(null)
    return (
        <Fragment>
            <label htmlFor={name}>{label}</label>
            <DatePicker
                name={name}
                className={styles.datePicker}
                placeholderText={label}
                inline={false}
                selected={date}
                showTimeSelect
                minDate={new Date()}
                dateFormat="dd/MM/yyyy h:mm aa"
                onChange={(date) => setDate(date)}
            />
        </Fragment>
    )
}

const FileInput = ({
    label,
    placeholder,
    onChange = () => {},
    required = false,
    className,
    name: _name,
    ...rest
}) => {
    let name = _name || encodeID(words(label, 2))
    return (
        <div className={cn(styles.inputContainer, className)}>
            <label htmlFor={name}>
                {label}
                {required ? <FaStarOfLife className={styles.required} /> : null}
            </label>
            <input
                {...rest}
                required={required}
                name={name}
                id={name}
                placeholder={placeholder || label}
                type="file"
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )
}
export default ({ type = 'text', comment, ...props }) => {
    let InputControl = null
    switch (type) {
        case 'text':
        case 'email':
        case 'number':
        case 'password':
            InputControl = TextInput
            break
        case 'checkbox':

        case 'checkbox':
            InputControl = Checkbox
            break
        case 'radio':
            InputControl = Radio
            break
        case 'textarea':
            InputControl = TextArea
            break
        case 'date':
            InputControl = DateInput
            break
        case 'file':
            InputControl = FileInput
            break
        default:
            InputControl = TextInput
            break
    }
    return (
        <>
            <InputControl {...props} type={type} />
            {comment && <p className={styles.comment}>{comment}</p>}
        </>
    )
}
