export const LOGIN_TOKEN_LOCAL_STORAGE_KEY = 'pep:loginToken'
export const USER_LOCAL_STORAGE_KEY = 'pep:user'
export const ACCOUNT_TYPE_OWNER = 'ACCOUNT_TYPE_OWNER'
export const ACCOUNT_TYPE_TENANT_BUYER = 'ACCOUNT_TYPE_TENANT_BUYER'

export const ACCOUNT_STATUS_NEW = 'new'
export const ACCOUNT_STATUS_NEEDS_MODIFICATION = 'needsModification'
export const ACCOUNT_STATUS_APPROVED = 'approved'
export const ACCOUNT_STATUS_BLOCKED = 'blocked'
export const ACCOUNT_STATUS_UPDATED = 'updated'

export const OFFER_STATUS_ACCEPTED = 'Accepted'
export const OFFER_STATUS_DECLINED = 'Declined'
