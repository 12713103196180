import React, { useEffect, useState } from 'react'

import styles from './styles.module.scss'

import SearchBox from '../search-box'

import cn from 'classnames'

function scrollTop() {
    var doc = document.documentElement
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    return top
}

export default () => {
    const [sticky, setSticky] = useState(false)
    // eslint-disable-next-line
    const [_, setSearchTerm] = useState(null)

    useEffect(() => {
        if (typeof window === 'undefined') return
        let lastScrollTop = 0
        window.addEventListener('scroll', () => {
            let top = scrollTop()

            let _searchTerm = null

            setSearchTerm(oldValue => {
                _searchTerm = oldValue
                return oldValue
            })

            setSticky(
                (top < lastScrollTop && top > 300) ||
                    (_searchTerm && _searchTerm.length)
            )

            lastScrollTop = top <= 0 ? 0 : top // For Mobile or negative scrolling
        })
    }, [])
    return (
        <div className={cn(styles.secondaryHeader, sticky && styles.sticky)}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12">
                        <SearchBox
                            innerPage
                            onTermChange={term => setSearchTerm(term)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
