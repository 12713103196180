import React, {
    useState,
    createContext,
    useCallback,
    useRef,
    useEffect
} from 'react'

import axios from 'axios'

import styles from './style.module.scss'

import debounce from 'lodash.debounce'

import Bowser from 'bowser' // ES6 (and TypeScript with --esModuleInterop enabled)

import cn from 'classnames'

// Site key: 6LfWmp8UAAAAALUW_z07f947PCjLx_qt-rWDHreW
// Secret key: 6LfWmp8UAAAAADK7KH5x6SjDVuWib0WWH6fONu3m

export const FormContext = createContext()

const instance = axios.create({
    baseURL: `${process.env.GATSBY_PROTOCOL}://${process.env.GATSBY_BASE_URL}`,
    timeout: 10000,
    auth: {
        username: 'pepAdmin',
        password: process.env.GATSBY_API_PASSWORD
    }
})

function validateEmail(email) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

function getTitle() {
    const path = window.location.pathname.replace('/', '')

    return 'Lead ' + (path.length ? `| ${path}` : '') + ` | ${document.title}`
}

function empty(val) {
    return !val || !val.length
}

export default ({
    children,
    onSuccess = () => {},
    onSubmit,
    noSuccessMessage,
    requiredFields,
    errorMessageClass,
    ...props
}) => {
    const [successMessage, setSuccessMessage] = useState(null)

    const [loading, setLoading] = useState(false)

    const [errorMessage, setErrorMessage] = useState(null)

    const [browser, setBrowser] = useState(null)

    const submit = useCallback(() => onFormSubmit())

    const formRef = useRef(null)

    const doSubmit = debounce(async function() {
        setLoading(true)

        let data = new FormData(formRef.current)

        var dataObject = {}

        data.forEach(function(value, key) {
            dataObject[key] = value
        })

        dataObject.browser_name = browser.name
        dataObject.browser_version = browser.version

        // TODO implement decent form validatation
        let errors = []

        for (var i = 0; i < requiredFields.length; i++) {
            let key = requiredFields[i]

            let elem = formRef.current.querySelector(`[name="${key}"]`)
            if (!elem) {
                console.error(
                    'Element (' + key + ') is required and cannot be found.'
                )
                continue
            }
            switch (elem.type) {
                case 'checkbox':
                    if (!elem.checked) {
                        errors.push('Please accept our privacy policy')
                    }
                    break
                default:
                    const message = 'Please fill required fields'
                    if (empty(dataObject[key])) {
                        if (errors.indexOf(message) === -1) {
                            errors.push(message)
                        }
                    }
            }
            if (key.match('email') && !validateEmail(dataObject[key])) {
                errors.push('Please enter valid email')
            }

            if (
                key.match('phone|mobile') &&
                // eslint-disable-next-line
                !dataObject[key].replace(/[ \+]/g, '').match(/^\d+$/)
            ) {
                errors.push('Please enter valid phone number')
            }
        }
        if (errors.length) {
            setLoading(false)
            return setErrorMessage(errors.join('<br>'))
        } else {
            setErrorMessage(null)
        }

        try {
            if (onSubmit) {
                await onSubmit(dataObject)
                setSuccessMessage(
                    <div className={styles.successMessage}>
                        Thank you, we have received your details. An expert will
                        get in touch with all needed details.
                    </div>
                )
                setLoading(false)

                setTimeout(function() {
                    onSuccess()
                }, 1500)
                return
            }

            await instance.post('/wp-json/wp/v2/lead', {
                title: getTitle(),
                content:
                    '<table>' +
                    Object.keys(dataObject)
                        .map(key => {
                            return `<tr><td>${key}</td><td>${dataObject[key]}</td></tr>`
                        })
                        .join('') +
                    '</table>'
            })
            setSuccessMessage(
                <div className={styles.successMessage}>
                    Thank you, we have received your details. An expert will get
                    in touch with all needed details.
                </div>
            )
            setTimeout(function() {
                onSuccess()
                setLoading(false)
            }, 1500)
        } catch (e) {
            console.error(e)
            setLoading(false)
            setErrorMessage('Error occured, please try again later.')
        }
    }, 300)

    async function onFormSubmit(event) {
        if (event) {
            event.preventDefault()
        }

        await doSubmit(event)
    }

    useEffect(() => {
        const _browser = Bowser.getParser(window.navigator.userAgent)

        setBrowser(_browser.getBrowser())
    }, [])

    return (
        <form
            {...props}
            className={cn(styles.form, props.className)}
            onSubmit={onFormSubmit}
            ref={formRef}
            noValidate
            disabled={loading}
        >
            <FormContext.Provider value={{ submit }}>
                {!successMessage || noSuccessMessage
                    ? children
                    : successMessage}
            </FormContext.Provider>

            {errorMessage && (
                <div
                    className={cn(styles.errorMessage, errorMessageClass)}
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                />
            )}
        </form>
    )
}
