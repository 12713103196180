import React, { useState, useEffect } from 'react'

import styles from './style.module.scss'

import { TiSocialFacebook } from 'react-icons/ti'

import { FaInstagram, FaTwitter } from 'react-icons/fa'

import cn from 'classnames'

const SOCIALS = [
    {
        link: 'https://business.facebook.com/propertyeportal',
        icon: <TiSocialFacebook />
    },
    {
        link: 'https://www.instagram.com/propertyeportal/',
        icon: <FaInstagram />
    },
    {
        link: 'https://twitter.com/propertyeportal',
        icon: <FaTwitter />
    }
]

const getScrollTop = () => {
    var doc = document.documentElement
    // var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    return top
}
const SocialBar = ({ inline = false }) => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (inline) return
        var lastScrollTop = 0
        window.addEventListener('scroll', () => {
            if (lastScrollTop > getScrollTop()) {
                setVisible(true)
            } else {
                setVisible(false)
            }
            lastScrollTop = getScrollTop()
        })
        // eslint-disable-next-line
    }, [])

    return (
        <div
            className={cn(
                styles.socialbar,
                { [styles.visible]: visible },
                { [styles.inline]: inline }
            )}
        >
            {SOCIALS.map(s => (
                <a href={s.link} key={s.link}>
                    {s.icon}
                </a>
            ))}
        </div>
    )
}

export default SocialBar
