import React, { useState, useMemo } from 'react'

import Popup from '../popup'

import { Link } from 'gatsby'

import Button from '../button'

import styles from './style.module.scss'

import { getToken } from '../../helpers'

import { useMyself } from '../hooks'

import { isEmpty, get } from 'lodash-es'

export default ({
    whenLoggedIn,
    whenNotLoggedIn,
    title,
    message,
    accountType,
    to,
    whenNotLoggedInContainerClassName,
}) => {
    const [isSignedIn] = useState(!isEmpty(getToken()))
    const { response: mySelfResponse } = useMyself()
    const [open, setOpen] = useState(false)

    const mySelf = get(mySelfResponse, 'data')

    const can = isSignedIn && mySelf && mySelf.account_type === accountType

    return can ? (
        whenLoggedIn
    ) : (
        <>
            <div
                onClick={() => setOpen(true)}
                className={whenNotLoggedInContainerClassName}
            >
                {whenNotLoggedIn}
            </div>
            <Popup
                open={open}
                onClose={() => setOpen(false)}
                header={title}
                footer={
                    <div className={styles.actions}>
                        <Link className={styles.signUp} to="/sign-up">
                            I don't have an account.
                        </Link>
                        <Button
                            className={styles.signIn}
                            link={
                                to
                                    ? '/sign-in?redirect=' + to
                                    : '/sign-in?redirect=/'
                            }
                        >
                            Sign in
                        </Button>
                    </div>
                }
            >
                <p>{message}</p>
            </Popup>
        </>
    )
}
